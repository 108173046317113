import * as React from 'react'
import Layout from '../layouts/default'

type Props = {};
const Error: React.FC<Props> = props => {
  return (
    <Layout title='Not Found | ComCard'>

    </Layout>
  )
}

export default Error
